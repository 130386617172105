
div#search-wrapper {

  margin-top: 10px;
  position: relative;


  div.input-feedback {

    position: relative;
    overflow: hidden;

    & > div.inner {
      position: relative;
      padding: 10px 5px;
      @include transition(all .15s ease-in-out);
      @include prefix(transform, translateY(100%));
      @include opacity(0);
    }

    &.found > div.inner {
      background: $green;
    }

    &.not-found > div.inner {
      background: $yellow;
    }

    &.misspelled > div.inner {
      background: $red;
    }

    &.active > div.inner {
      @include prefix(transform, translateY(0));
      @include opacity(1);
    }

    p {
      color: $white;
      text-align: center;
      font-size: 16px;
      margin: 0;
    }

  }//end input-feedback


  form#search-form {
    margin-bottom: 20px;
  }


  .input.word-input-field {
    display: block;
    width: 100%;
    border: none;
    outline: solid 1px transparent;
    background: $light-grey;
    @include border-radius(0);
    @include prefix(box-shadow, none);
    text-align: center;
    font-size: $fs-lg;
    padding: 5px 10px;
    @include transition(outline .3s ease-in-out);
    @include placeholders($mid-grey);

    &:hover, &:focus, &:active {
      outline: solid 1px $grey;
    }

    @media(max-width: 320px) {
      font-size: $fs-keyboard;
    }

  }//end input field


  button#userWordBtn {
    width: 120px;
    font-size: $fs-keyboard;
    padding: 2px 10px;

    &.wobble {
      -webkit-animation: wobble 0.8s both;
	          animation: wobble 0.8s both;
    }

  }


}//end div#search-wrapper



//Search and keyboard section styles for touch devices
html.touchevents {

  div#search-wrapper {

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    z-index: 100;
    background: $white;
    padding: 0 5px;
    max-width: $game-area-width-touch;

    @media(min-width: 1024px) {
      max-width: $game-area-width;
    }

    form#search-form {
      margin-bottom: 0;
    }

    .input.word-input-field {
      &:hover, &:focus, &:active {
        outline: none;
      }
    }

    span.desktop-search-submit {
      display: none;
    }



    div.input-feedback {
      position: absolute;
      width: calc(100% - 10px);
      left: 5px;
      bottom: 100%;
      div.inner {
        padding: 11px 5px;
      }

      p {
        font-size: $fs-md;

        @media(max-width: 320px) {
          font-size: 16px;
        }
      }
    }//end input feedback


  }//end search wrapper

}//end html.touchevents {









