footer#footer {

  padding-top: 40px;

  div.footer-logo {
    img {
      display: block;
      width: auto;
      height: 24px;
      max-width: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  p.copyright {
    margin-top: 20px;
    font-size: 16px;
    color: $mid-grey;
  }

  div.footer-nav {
    ul li {
      @media(max-width: 320px) {
        font-size: 16px;
      }
    }
  }

}//end footer