ul.difficulty-level {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  align-items: center;

  li {
    flex: 1;

    & + li {
      margin-left: 10px;
    }
  }

  button {
    padding: 2px 5px;
    margin: 0;
    text-align: center;
    //border: solid 1px $light-grey;
    border: solid 1px $grey;
    //background: transparent;
    background: $light-grey;
    @include transition(all .3s ease-in-out);
    width: 100%;

    &:hover, &:focus, &:active, &.active {
      border-color: $black;
    }

    &.active {
      background: $black;
      color: $white;
      cursor: default;
    }

  }
}