@import "partials/require";
@import "common/require";
@import "layouts/require";
@import "components/require";
@import "pages/require";
@import "game-elements/require";
//@import "misc_unused/misc";

body, html {
  overflow-x: hidden;
}

//Boostrap overrides for small screens
@media(max-width: 320px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

span.splitter-line {

  display: block;
  width: 100%;
  height: 1px;
  background: $light-grey;
  margin: 8px 0;

}//end splitter-line
//Utils
