//Position in the center of the screen and set a max-width
.notifyjs-corner {
  left: 0!important;
  right: 0!important;
  width: 320px;
  margin: 5px auto!important;

  .notifyjs-wrapper, .notifyjs-container {
    margin: 0;
  }

  .notifyjs-bootstrap-error {
    background-image: none;
    padding: 8px 15px!important;
    color: $black;
    background-color: $white;
    border-color: $red;
    text-align: center;
    @include border-radius(0);
    animation: none;
    font-weight: 400;
  }
}