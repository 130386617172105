
//Font-weights:
//Book (400)
//Book Oblique (400)
//Medium (500)
//Medium Oblique (500)
//Demi (600)

$base-fs: 16px;
$fs-xxlg: 40px;
$fs-xlg: 30px;
$fs-lg: 26px;
$fs-keyboard: 24px;
$fs-header: 20px;
$fs-md: 18px;
$fs-sm: 14px;

body {
  font-family: $ff-copy;
  font-size: 18px;
  color: $pblack;
  font-weight: 400;
  line-height: 1.2;
}

