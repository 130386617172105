div.share {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;


  div.sharebtn {
    margin: .25em;
    padding: 0;

    &:after {
      content: "";
      clear: both;
      display: table;
    }

    button {
      background: transparent;
      font-size: 2em;
      border: none;
      cursor: pointer;

      &:hover {
        color: $light-grey;
      }
    }

  }//end sharebtn

}//end share

