header#header {

  padding: 8px 0;

  div.header-wrap {
    display: flex;
    align-items: center;
  }


  div.header-logo {
    
    flex: 0 0 auto;
    width: 30%;

    img {
      display: block;
      width: auto;
      //height: 24px;
      height: 36px;
      max-width: 100%;
      object-fit: contain;
      object-position: left;
    }
  }//end div.header-logo {


  div.stats-wrap {

    flex: 0 0 auto;
    width: 40%;
    display: flex;
    justify-content: center;

    span.icon-wrap, span.stat-container {
      display: inline-block;
      vertical-align: middle;
    }

    span.stat-container {
      padding-left: 4px;
      //font-size: $fs-sm;
      font-size: $fs-header;
      //font-weight: 600;
    }

  }//end stats-wrap


  div.info-wrap {
    
    flex: 0 0 auto;
    width: 30%;
    display: flex;
    justify-content: flex-end;

  }//end info-wrap


  ul.header-icon-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      & + li {
        margin-left: 15px;
      }
    }
  }


  button.header-btn {

    background: transparent;
    padding: 0;
    margin: 0;
    display: block;
    border: none;
    outline: none;

    span.icon-wrap {
      font-size: $fs-lg;
      img {
        //width: 20px;
        //height: 20px;
        width: 24px;
        height: 24px;
      }
    }

  }//end button.header-btn {


/*
  .color-hint {
    color: $orange;
  }
  .color-found {
    color: $green;
  }
  .color-hint {
    color: $orange;
  }
  */

  //Mobile
  @media(max-width: 1200px) {

    div.header-logo {
      //width: 30%;
      width: 25%;
      img {
        height: 24px;
      }
    }

    div.stats-wrap {
      width: 50%;
      //justify-content: flex-end;
      justify-content: center;
      //padding-right: 10px;
      
      span.stat-container {
        padding-left: 4px;
        font-size: $fs-sm;
        font-weight: 600;
      }
    }

    div.info-wrap {
      //width: 20%;
      width: 25%;
    }


    ul.header-icon-list {
      li + li {
        margin-left: 10px;
      }
    }
    
    button.header-btn {
      span.icon-wrap {
        font-size: $fs-sm;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }

  }//end 1200


  @media(max-width: 767px) {
    div.stats-wrap {
      padding-right: 0;
    }
  }


  @media(max-width: 320px) {

    div.header-logo {
      width: 25%;
    }

    div.stats-wrap {
      width: 55%;
      padding-left: 10px;
    }

    ul.header-icon-list {
      li + li {
        margin-left: 8px;
      }
    }

    button.header-btn span.icon-wrap img {
      width: 18px;
      height: 18px;
    }

  }//end 320
  //end mobile


  //For standard pages
  &.default-header {

    div.header-logo {
      width: 100%!important;
    }

    button#backBtn {
      padding: 0;
      margin: 0;
      background: transparent;
      border: none;
      outline: none!important;

      i {
        color: $black;
        font-size: $fs-keyboard;
      }

      &:hover, &:focus, &:active {
        i {
          color: $mid-grey;
        }
      }
    }

  }//end &.page-header {









}//end header#header {