
//General modal styles
@media(max-width: 767px) {
  .modal-backdrop {
    display: none!important;
  }
}

p.section-info {
  padding: 5px 0;
  font-size: $fs-md;
}

div.modal {

  div.modal-dialog {
    //max-width: 375px;
    max-width: 80%;
  }


  div.modal-content {
    padding: 0;
    margin: 0 auto;
    @include border-radius(0);
  }//end modal-content


  div.modal-header {

    background: $black;
    padding: 4px 10px;
    color: $white;
    position: relative;


    &.tries, &.hints, &.stats {
      //padding: 0 10px;
      padding: 4px 10px;
      h3 {
        //font-size: $fs-xxlg;
        font-size: $fs-xlg;
        line-height: normal;
      }
    }


    //Tries modal header
    &.tries {
      //background: $green;
      background: $silver;
    }//end &.tries {


    &.hints {
      background: $orange;
    }//end hints


    &.stats {
      //background: $silver;
      background: $green;
    }//end hints


    h3 {
      font-size: $fs-xlg;
      padding-right: 30px;

      &.icon {
        img {
          width: 30px;
          height: 30px;
          display: inline-block;
          vertical-align: middle;
        }

        span {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 30px);
          padding-left: 10px;
        }
      }
    }


    button.close {
      position: absolute;
      top: 50%;
      right: 10px;
      @include prefix(transform, translateY(-50%));
      margin: 0;
      padding: 0;
      @include opacity(.85);

      &:hover, &:focus, &:active {
        @include opacity(1);
      }

      img {
        width: 20px;
        height: 20px;
      }
    }//end button close

  }//end modal-header


  div.modal-body {

    padding: 20px 10px 0;

    div.modal-section {

      position: relative;
      & + div.modal-section {

        margin-top: 10px;
        padding: 20px 0 10px 0;
        //padding-top: 12px;
  

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: $light-grey;
        }
      }



      p {
        margin-bottom: 0;
      }

    }//end modal-section


    h4 {
      //font-size: $fs-keyboard;
      //font-size: $fs-lg;
      font-size: fs-header;
      font-weight: 600;
      //margin: 0 0 12px;
      margin: 0 0 16px;
    }


    h4.icon {
      img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        display: inline-block;
      }

      span {
        display: inline-block;
        width: calc(100% - 20px);
        padding-left: 10px;
        vertical-align: middle;
      }
    }

    .layout {
      border: 1px solid $coral;
    }
    .layout-blue {
      border: 1px solid lightblue;
    }
    
    .demo_search {
      display: inline-block;
      background-color: $light-grey;
      border: 1px solid $grey;
      padding: 5px 20px 5px 5px;
      font-family: monospace, monospace;
    }
    .demo_type {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      border-right: 2px solid $grey;
      width: 100%;
      animation: typewriter 7s steps(5, end) forwards infinite, cursor 1s infinite;
    }
    
    .demo_button {
      display: inline-block;
      border: 1px solid $grey;
      padding: 5px;
      background-color: $grey;
      color: $white;
      
      animation: clicking 7s forwards infinite;
    }
    .demo_try {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }
    
    @keyframes typewriter {
      0% { width: 0%; }
      20% { width: 100%; } // Finish changes
      100% { width: 100%; } //no changes
    }
    
    @keyframes cursor {
      0% {border-color: transparent}
      50% {border-color: $grey}
      100% {border-color: transparent}
    }
    
    @keyframes clicking {
      0% {background-color: $grey;}
      35% {background-color: $grey;}
      36% {background-color: $black;}
      40% {background-color: $black;}
      41% {background-color: $grey;}
      100% {background-color: $grey;}
    }
    
    .demo {
      //display: inline;
    }
    .demo-filled {
      background: $white;
      color: $black;
      padding: 3px;
      line-height: 2em;
      letter-spacing: 2px;
    }
    .demo:before {
      content: "b__ks.";
      background: $light-grey;
      padding: 3px;
      line-height: 2em;
      letter-spacing: 2px;
      animation: demo_hide_blanks 7s forwards infinite;
    }
    
    @keyframes demo_hide_blanks {
      0% {
        content: "b__ks.";
        background: $light-grey;
        color: $black;
      }
      39% {
        content: "b__ks.";
        background: $light-grey;
        color: $black;
      }
      40% {
        content: "books.";
        background: $green;
        color: $white;
        //border: 2px solid $green;
      }
      60% {
        content: "books.";
        background: $green;
        color: $white;
        //border: 2px solid $green;
      }
      67% {
        content: "books.";
        background: $white;
        color: $black;
        //border: 2px solid $green;
      }
      100% {
        content: "books.";
        background: $white;
        color: $black;
        //border: 2px solid $green;
      }
    }


  }//end modal-body


  div.modal-footer {

    border: none;
    position: relative;

    margin-top: 10px;
    padding: 12px 10px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: $light-grey;
    }

  }//end modal-footer



  //Make the modal full width and height on mobile / touch devices
  @media(max-width: 767px) {


    //Change the fade transition to a slide down.


    &.fade {
      @include opacity(1);
      top: -100vh;
      @include transition(top .15s ease-in-out);

      &.in {
        top: 0;
      }
    }


    div.modal-dialog {
      max-width: none;
      width: 100%;
      margin: 0 auto;
      min-height: 100vh;
    }

    div.modal-content {
      border: none;
      min-height: 100%;
    }

  }//end mobile



}//end modal


//Welcome modal
div#welcome, div#settings {

  ul.difficulty-level {
    margin: 12px 0 12px 0;
  }

}//end div#welcome


div#tries-modal {

  div.modal-body {
    padding-bottom: 20px;
  }

  p.tries-modal-progress {
    margin-bottom: 10px;
  }

  p.tries-words-heading {
    font-size: 16px;
  }

}//end tries-modal


div#hints-modal {

  div.modal-body {
    padding-bottom: 20px;
  }

  p.tries-modal-progress {
    margin-bottom: 10px;
  }

  p.tries-words-heading {
    font-size: 16px;
  }

}//end tries-modal


div#stats-modal {

  div.modal-body {
    padding-bottom: 20px;
  }


  div.stats-container {

    & > div + div {
      margin-top: 15px;
    }

  }//end stats-container


  p.stat-value, p.stat-label {
    display: inline-block;
    vertical-align: middle;
  }

  p.stat-value {

    width: 94px;
    text-align: center;
    line-height: 94px;
    @include border-radius(100%);
    color: $white;
    font-weight: 600;
    font-size: $fs-xlg;

  }//end p.stat-value {


  p.stat-label {
    width: calc(100% - 94px);
    padding-left: 10px;
  }


  div.completed {
    p.stat-value {
      //background: $silver;
      background: $green;
    }

    p.stat-label {

    }
  }

  div.tries {
    p.stat-value {
      //background: $green;
      background: $silver;
    }
  }

  div.hints {
    p.stat-value {
      background: $orange;
    }
  }


}//end div#stats-modal {


div#information {

  div.modal-footer {
    border: none;
    padding-top: 30px;
    margin-top: 0;

    &:before {
      display: none;
    }
  }


  nav {
    margin-top: 5px;
  }


  div.copyright-wrap {

    display: flex;

    & > div {
      width: 50%;
      flex: 0 0 50%;
    }

    p {
      margin-bottom: 0;
      color: $mid-grey;
      font-size: 16px;
    }

    p.copyright {
      text-align: left;
    }

  }//end copyright-wrap

}//end div#information {


div#settings {

  div.modal-body {
    padding-bottom: 20px;
  }

  p + .btn {
    margin-top: 10px;
  }
  
  div.modal-footer {
    border: none;
    padding-top: 30px;
    margin-top: 0;
    
    &:before {
      display: none;
    }
  }
  
  div.copyright-wrap {
  
    display: flex;
  
    & > div {
      width: 50%;
      flex: 0 0 50%;
    }
  
    p {
      margin-bottom: 0;
      color: $mid-grey;
      font-size: 16px;
    }
  
    p.copyright {
      text-align: left;
    }
  
  }//end copyright-wrap

}//end div#settings {



ul.share-links {

  margin: 0;
  padding: 0;
  margin-top:5px;
  display: block;
  list-style: none;
  text-align: center;

  li {
    display: inline-block;
    vertical-align: middle;

    & + li {
      margin-left: 10px;
    }
  }

  button {
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    background: transparent;
    display: block;


    img {
      width: 20px;
      height: 20px;
    }
  }
}//end share-links



div#cta {

  div.modal-body {
    padding-bottom: 20px;
  }
  
  div.modal-paragraph {
    padding-top: 10px;
  }

  p + .btn {
    margin-top: 10px;
  }
  
  div.modal-footer {
    border: none;
    padding-top: 30px;
    margin-top: 0;
    
    &:before {
      display: none;
    }
  }
  
  div.copyright-wrap {
  
    display: flex;
  
    & > div {
      width: 50%;
      flex: 0 0 50%;
    }
  
    p {
      margin-bottom: 0;
      color: $mid-grey;
      font-size: 16px;
    }
  
    p.copyright {
      text-align: left;
    }
  }
  
  div.cta-share {
    padding: 12px 10px 0;
    margin-top:12px;
    
    p {
      margin-bottom: 0;
    }
  
    h4 {
      margin-top: 0;
    }
    img {
      width: 30px;
      height: 30px;
    }
  }//end div.winner-share, div.support, div.thanks {

}//end div#cta {


/**

  Winner modal:

    - Two styles:
      - Static gif
      - Dynamic gif
    - Type set by adding a class to the modal
      - Use static-gif to show the static gif
      - Or, use dynamic gif
 */
$static-bg-color: #f8f9fb;
div#wwcd {


  h3 {
    font-size: $fs-xxlg;
    margin: 0;
    font-weight: 600;

    & + p {
      font-size: $fs-md;
      font-weight: 400;
      margin-bottom: 20px;
    }
  }


  div.modal-header {
    border: none;
    padding: 10px;

    img.logo {
      display: block;
      width: auto;
      height: 24px;
    }
  }


  ul.winner-stats {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      text-align: center;
    }

    span.bubble {

      display: block;
      text-align: center;
      width: 94px;
      line-height: 94px;
      color: $white;
      font-weight: 600;
      font-size: $fs-xlg;
      @include border-radius(100%);
      margin: 0 auto;

      &.success-rate {
        background: $green;
      }

      &.hints-used {
        background: $orange;
      }

    }//span.bubble {

    span.stat-label {
      display: block;
      margin-top: 10px;
      text-align: center;
      font-weight: 500;
    }

  }//end winner-stats


  


  div.winner-gif-wrap {

    img {
      width: 100%;
      height: 175px;
      object-fit: cover;
      object-position: center;
    }

  }//end div.winner-gif-wrap {


  div.winner-share, div.support, div.thanks {
    padding: 12px 10px 0;
    margin-top:12px;
    
    p {
      margin-bottom: 0;
    }

    h4 {
      margin-top: 0;
    }
  }//end div.winner-share, div.support, div.thanks {


  div.thanks {
    padding-bottom: 30px;
  }


  div.support {
   border-top: 1px dotted $mid-grey;
    a {
      text-decoration: underline;
      //text-decoration-color: transparent;
      @include transition(text-decoration-color .3s ease-in-out);

      &:hover, &:focus, &:active {
        text-decoration-color: $black;
      }
    }
  }


  div.modal-static-gif {
    display: none;

    div.modal-header {
      background: $static-bg-color;
    }

    div.modal-body {
      background: $static-bg-color;
    }


    div.winner-share, div.support, div.thanks {
      background: $black;
      color: $white;
      margin: 0 -10px;
    }//end div.winner-share, div.support, div.thanks {


    div.support {
      a {
        color: $white;
        &:hover, &:focus, &:active {
          text-decoration-color: $white;
        }
      }
    }

  }//end div.modal-static-gif {


  div.modal-dynamic-gif {

    div.modal-body {
      padding-top: 0;
    }//end .modal-body

    div.winner-gif-wrap {
      //margin: 0 -10px 20px;
      margin: 0;
    }//end winner-gif-wrap


    div.support {
      a {
        color: $black;
        &:hover, &:focus, &:active {
          text-decoration-color: $black;
        }
      }
    }

  }//end div.modal-dynamic-gif {


  img {
      max-width: 80%;
      max-height: 150px;
      display:block;
      margin:auto;
  }
  
  
  .gif-container {
      width: 100%;
      height: auto;
      margin-bottom:5px;
      margin-top:5px;
      position:relative;
      //background: $black;
  }


  div.modal-dynamic-gif {
    display: none;
  }//end div.modal-dynamic-gif {


  &.static-gif {
    div.modal-static-gif {
      display: block;
    }//end div.modal-static-gif {
  }


  &.dynamic-gif {
    div.modal-dynamic-gif {
      display: block;
    }//end div.modal-static-gif {
  }


}//end wwcd




ul.modal-words {
  display: block;
  padding: 0;
  margin: 10px 0 0;
  list-style: none;

  li {

    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;

    span.word {
      background: $light-grey;
      padding: 3px;
      line-height: 2em;
      letter-spacing: 2px;
      cursor: default;
    }

    span.highlight_found {
      background-color: $green;
      color: $white;
    }//end span.word.em-normal.highlight_found

    span.cheated {
      color: $orange;
    }
    
    img {
      //width: 30px;
      height: 2em;
      display: inline-block;
      vertical-align: middle;
    }
    
    &.icon {
      img {
        width: 1em;
        height: 1em;
        display: inline-block;
        vertical-align: middle;
      }
    }

  }//end li

}//end ul.example


ul.modal-list {

  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding-left: 20px;
    position: relative;

    &:before {
      content: "";
      width: 12px;
      height: 12px;
      border: solid 1px $mid-grey;
      display: block;
      position: absolute;
      top: 5px;
      left: 0;
    }

    & + li {
      margin-top: 10px;
    }

  }//end li

}





































