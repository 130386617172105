body.privacy {

  .underline {
    border-bottom: 1px solid #CCCCCC;
  }

  .question {
    font-weight: bold;
    margin-top: 20px;
  }

  .answer {
    margin-bottom: 10px;
  }

}