div.link {
  color: $blue;
  text-decoration: underline;
  //text-decoration-color: transparent;
  @include transition(text-decoration-color .3s ease-in-out);

  &:hover, &:focus, &:active {
    text-decoration-color: $black;
  }
}
body.faq {
  .question {
    font-weight: bold;
    margin-top: 20px;
  }

  .answer {
    margin-bottom: 10px;
  }
}
