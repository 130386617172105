.underline {
  border-bottom: 1px solid $light-grey;
  padding-bottom: 10px;
}

.content-wrap {

  a:not(.btn) {

    color: $black;
    font-weight: 500;
    text-decoration: underline;
    text-decoration-color: transparent;
    @include transition(text-decoration-color .3s ease-in-out);

    &:hover, &:focus, &:active {
      text-decoration-color: $black;
    }

  }


  ul {

    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding-left: 20px;
      position: relative;

      &:before {
        content: "";
        width: 12px;
        height: 12px;
        border: solid 1px $mid-grey;
        display: block;
        position: absolute;
        top: 5px;
        left: 0;
      }

      & + li {
        margin-top: 10px;
      }

    }//end li

  }

}