
div.stats-wrapper {
  display: flex;
  flex-flow: row nowrap;


  .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 250px;
  }

  .circle-bg {
    fill: none;
    stroke: $light-grey;
    stroke-width: 3.8;
  }

  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .circular-chart.red .circle {
    stroke: $red;
  }

  .circular-chart.green .circle {
    stroke: $green;
  }

  .circular-chart.blue .circle {
    stroke: $blue;
  }

  .percentage {
    fill: $mid-grey;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
  }

  .number {
    fill: $mid-grey;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
  }

  .single-chart {
    width: 33%;
    justify-content: space-around;
  }


}//end stats wrapper


//For the headings above the stats circles - make the number of words larger
.bigger {
  font-size: 1.15em;
}