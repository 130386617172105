body.contact {

  label {
    color: $black;
    font-size: $fs-md;
  }
  
  .mobile {
    display:none;  //honeypot
  }
  
  .feedback-alert {
    padding: 10px;
    background: transparent;
    color: $white;
    margin-bottom: 10px;
    display: none;
  }
  
  .alert-success {
    background: $green;
    display: block;
  }
  .alert-error {
    background: $red;
    display: block;
  }
  
  /* Add a background color and some padding around the form */
  .contact-form {
    width: 50%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: auto;
  }
  .error {
    color: $red;
    display: block;
    padding: 6px 0;
    font-size: $fs-sm;
  }
  .contact-form.error {
    border-color: $red;
    padding: .375rem .75rem;
  } 
  
  .helptext {
    padding-top: 10px;
    padding-bottom: 20px;
  }
  
  .contactInput {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */  
    border: 1px solid $grey; /* Gray border */
    background-color: $light-grey;
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
    font-size: $fs-sm;
  }
  

  /* Style the submit button with a specific background color etc */
  input[type=submit] {
    background-color: $light-grey;
    color: $black;
    border: 1px solid $black;
    padding: 12px 20px;
    cursor: pointer;
  }

  /* When moving the mouse over the submit button, add a darker green color */
  input[type=submit]:hover {
    background-color: $black;
    color: $white;
  }
  
  .required {
    color: $red;
    font-size: $fs-md;
  } 
}

//Make the full width and height on mobile / touch devices
@media(max-width: 767px) { 
  body.contact {
    .contact-form {
      width: 100%; /* Full width */
    }
  }
}