@keyframes fadeout {
  to {
    color: $black;
    background-color: transparent;
  }
}


/**
 * ----------------------------------------
 * animation wobble
 * ----------------------------------------
 */
@-webkit-keyframes wobble {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-15px) rotate(-3deg);
            transform: translateX(-15px) rotate(-3deg);
  }
  30% {
    -webkit-transform: translateX(8px) rotate(3deg);
            transform: translateX(8px) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-8px) rotate(-1.5deg);
            transform: translateX(-8px) rotate(-1.5deg);
  }
  60% {
    -webkit-transform: translateX(5px) rotate(1.2deg);
            transform: translateX(5px) rotate(1.2deg);
  }
  75% {
    -webkit-transform: translateX(-3px) rotate(-1deg);
            transform: translateX(-3px) rotate(-1deg);
  }
}
@keyframes wobble {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-15px) rotate(-3deg);
            transform: translateX(-15px) rotate(-3deg);
  }
  30% {
    -webkit-transform: translateX(8px) rotate(3deg);
            transform: translateX(8px) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-8px) rotate(-1.5deg);
            transform: translateX(-8px) rotate(-1.5deg);
  }
  60% {
    -webkit-transform: translateX(5px) rotate(1.2deg);
            transform: translateX(5px) rotate(1.2deg);
  }
  75% {
    -webkit-transform: translateX(-3px) rotate(-1deg);
            transform: translateX(-3px) rotate(-1deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}
@keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}