$game-area-width: 450px;
$game-area-width-touch: 650px;

body.home {

  //prompt mobile users to rotate their devices
  div#rotate-notice {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    z-index: -1;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    display: none;
    text-align: center;

    img.rotate-img {
      width: 100%;
      max-width: 90px;
      margin: 0 auto;
      @include transition(all .4s ease-in-out);

      &.rotate {
        -webkit-animation: rotate-90-cw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: rotate-90-cw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }
    }

    p {
      margin-top: 15px;
    }

  }//end rotate-notice


  div.container.game-container {
    max-width: $game-area-width;
  }

  //keyboard hidden by default. Show if device is touch enabled.
  div.keyboard-wrapper {

    &.kb-hidden {
      display: none;
    }

  }//end keyboard-wrapper


  div.intro-wrapper {

    //background: $black;
    background: $white;

    p#intro {

      //color: $white;
      color: $black;
      padding: 10px 5px;
      margin: 0;
      font-size: 16px;

      @media(max-width: 991px) {
        font-size: $fs-sm;
      }

      a {
        //color: inherit;
        color: #3c9ee5;
        text-decoration: underline;
        text-decoration-color: $white;
      }


      strong {
        font-style: italic;
        font-weight: 500;
      }


    }//end intro



  }//end div.intro-wrapper {



  div.announce-wrapper {
  
    //background: $black;
    background: $white;
  
    p#announce {
  
      //color: $white;
      //background: $green;
      color: $black;
      padding: 10px 5px;
      margin: 0;
      font-size: 16px;
  
      @media(max-width: 991px) {
        font-size: $fs-sm;
      }
  
      a {
        //color: inherit;
        color: #3c9ee5;
        text-decoration: underline;
        text-decoration-color: $white;
      }
  
  
      strong {
        font-style: italic;
        font-weight: 500;
      }
  
  
    }//end intro
  
  
  
  }//end div.announce-wrapper {

}//end body home



//Touch device:
html.touchevents body.home {

  .container {
    max-width: $game-area-width-touch;

    @media(min-width: 1024px) {
      max-width: $game-area-width;
    }
  }

  div.container.game-container {
    margin-top: 20px;

    @media(max-width: 320px) {
      margin-top: 10px;
    }
  }

  div.intro-wrapper {
    margin: 0 -15px;
    padding: 0 15px;

    p#intro {
      padding: 10px 0;
    }
  }
  
  div.announce-wrapper {
    margin: 0 -15px;
    padding: 0 15px;
  
    p#announce {
      padding: 10px 0;
    }
  }

  @media(max-width: 767px) {

  }

  @media screen and (max-height: 450px) and (orientation: landscape) {

    div#rotate-notice {
      z-index: 2000;
      display: flex;
    }

  }

}//end html.touchevents body.home


//Not touch device:
html.no-touchevents {

  div#rotate-notice {
    display: none;
  }

  div.container.game-container {
    margin-top: 60px;
  }

}//end html.no-touchevents




























