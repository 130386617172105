@mixin prefix($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -o-#{$property}: $value;
  #{$property}: $value;
}

@mixin opacity($value){
  $IEValue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
  filter: alpha(opacity=$IEValue);
}

@mixin placeholders ($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}

@mixin border-radius($radius) {
  border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}

@mixin transition($values) {
  -webkit-transition: $values;
  -moz-transition: $values;
  -o-transition: $values;
  transition: $values;
}