//The puzzle
.phraseotheday {

  padding: 0;
  margin: auto;
  font-size: $fs-md;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;


  //The words within the puzzle
  .word {
    margin: 0 5px 5px 0;
    background-color: $light-grey;
    padding: 3px;
    line-height: 1.4;
    cursor: pointer;
    letter-spacing: 2px;
    //border: 1px dotted $mid-grey;

    @include transition(all .3s ease-in-out);


    //The word has been completed class
    &.done {
      background: $white;
      cursor: default;
      border: none;
    }//end done


    //If word is hyphenated remove spacing
    &.hyphen {
      margin-right: 0em;
    }//end hyphen


    //Not sure this is being used anywhere
    &.toggle * { /* all children */
      background-color: transparent;
    }

    &.toggle {
      background-color: $light-blue;
    }
    //Not sure this is being used anywhere


    //Successfully solved a word
    &.highlight_found {
      background-color: $green;
      color: $white;

      span.cheated {
        color: $white;
      }
      //animation: fadeout 1s forwards;
      //animation-delay: 2s;
    }//end highlight_found


    //Word is already solved
    &.highlight_exsits {
      background-color: $light-blue;
      color: $white;
      animation: fadeout 1s forwards;
      animation-delay: 2s;
    }//end .highlight_exsits


    //user surrendered, highlight unsolved revealed words
    &.highlight_surrender {
      background-color: $coral;
      animation: fadeout 1s forwards;
      animation-delay: 2s;
    }//end highlight_surrender


    //Add a light green background - NOT SURE THIS IS IN USE
    &.highlight_completed {
      background-color: $light-green;
      animation: fadeout 1s forwards;
      animation-delay: 2s;
    }//end highlight_completed


    //Add an underline to words when user surrenders
    .surrendered {
      text-decoration: underline;
    }//end surrendered


    //User used a hint to find this letter - revealed when puzzle completed / surrendered
    &.cheated {
      color: $orange;
    }//end cheated


    span.cheated {
      @include transition(color .3s ease-in-out);
      color: $orange;
    }

    /*@media(max-width: 520px) {
      font-size: 18px;
      letter-spacing: 1px;
    }*/

    @media screen and (max-height: 700px) and (orientation: portrait) {
      font-size: 16px;
      letter-spacing: 1px;
    }

    @media(max-width: 320px) {
      font-size: 16px;
      letter-spacing: 1px;
      margin: 0 2px 2px 0;
      padding: 2px;
      line-height: 1.2;
    }


  }//end word

}//end phrase