.btn  {

  @include border-radius(0);
  border: none;
  outline: solid 1px transparent;
  color: $white;
  background: $grey;
  @include transition(all .3s ease-in-out);
  font-size: $fs-md;

  &:hover, &:focus, &:active {
    outline: solid 1px $black!important;
    background: $black;
    color: $white;
  }



  &.btn-white {

    //background: transparent;
    background: $light-grey;
    //border: solid 1px $light-grey;
    border: solid 1px $grey;
    color: $black;
    //text-transform: uppercase;
    font-weight: 500;

    &:hover, &:focus, &:active {
      outline: none!important;
      background: $black;
      color: $white;
      border-color: $black;
    }

  }//end btn-white


  &.btn-small {
    padding: 2px 15px;
  }


}