.footer-nav {
  ul {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      vertical-align: middle;
      position: relative;

      a {
        font-weight: 600;
        color: $black;
        text-decoration: underline;
        text-decoration-color: transparent;
        @include transition(text-decoration-color .3s ease-in-out);

        &:hover, &:focus, &:active {
          text-decoration-color: $black;
        }
      }

      & + li {
        padding-left: 10px;

        &:before {
          content: '|';
          position: absolute;
          left: 0;
          top: 0;
          color: $mid-grey;
        }
      }
    }
  }
}