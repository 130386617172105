div.keyboard-wrapper {

  margin: 0 -5px;


  div.simple-keyboard {

    &.hg-theme-default {

      background: $white;
      padding: 10px 5px;

      div.wrodeo-keyboard-button {
        background: $grey;
        color: $white;
        font-weight: 400;
        font-family: $ff-copy;
        font-size: $fs-keyboard;
        height: 45px;

        &.hg-activeButton {
          background: $black;
        }


        //Small mobile devices:
        @media(max-width: 320px) {
          font-size: $fs-md;
          height: 36px;
        }


      }//end button

    }//end hg-theme-default

  }//end simple-keyboard

}//end div.keyboard-wrapper {



