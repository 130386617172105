
$red: #ff0000;

$green: #4CC790;
$light-green: #90EE90;

$blue: #3c9ee5;
$light-blue: #87CEFA;

$white: #fff;
$black: #000;
$pblack: #000;

$coral: lightcoral;



$light-grey: #f0f0f0;// 240 240 240
$mid-grey: #b9b9b9;// 185 185 185
$grey: #646464; // 100 100 100

$green: #73bc9e;  //115 188 158
$yellow: #d39732;
$orange: #e1624b;  //225 98 75
$red: #d94c22;
$silver: #a5a5a5; // 165 165 165

$off-white: #f1f1f1;

$c-primary: $black;
$c-primary-hover: lighten($black, 15);

$c-secondary: $light-grey;

.silver {
  color: $silver;
}

.mid-grey {
  color: $mid-grey;
}

.grey {
  color: $grey;
}

.green  {
  color: $green;
}

.orange {
  color: $orange;
}

.green-bg {
  background: $green;
  color: $white;
}

.orange-bg {
  background: $orange;
  color: $white;
}